<template>
  <v-container>
    <v-card
      v-if="loaded"
    >
      <v-card-text>
        <p class="display-1 text--primary">
          <v-icon
            x-large
            :color="setStatusColor(olt.enable, olt.uptime.uptime)"
          >
            mdi-switch
          </v-icon>
          {{ olt.name }} ({{ olt.ip }})
        </p>
        <v-chip
          class="ma-2"
          color="primary"
        >
          <v-icon left>
            mdi-domain
          </v-icon>
          {{ olt.officeSector.name }}
        </v-chip>
        <br>
        <b>Brand:</b> {{ olt.oltType.oltBrand.name }}<br>
        <b>Type:</b> {{ olt.oltType.name }}<br>
        <b>Project:</b> {{ olt.oltProject.name }}<br>
        <b>Uptime:</b> {{ calSysUpTime(olt.uptime.uptime) }}
        (Updated at: {{ formatDate(olt.uptime.updatedAt) }} )<br>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

moment.locale('th')

export default {
  name: 'OltDetailCard',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      olt: '',
      loaded: false,
    }
  },
  computed: {

  },
  watch: {
    options: {
      handler() {
        // this.getDataFromAPI()
        this.showOlt()
      },
      deep: true,
    },
    search: {
      handler() {
        // this.getDataFromAPI()
        this.showOlt()
      },
      deep: true,
    },
  },
  mounted() {
    this.loaded = false
    this.showOlt()
  },
  created() {

  },
  methods: {
    showOlt() {
      this.loaded = false
      axios.get(`/olt/${this.id}`)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.olt = response.data
          this.loaded = true
        })
        .catch((e) => {
          this.errors.push(e)
          // this.loaded = false
        })
    },
    setStatusColor(enable, uptime) {
      if ((enable) && (uptime !== null)) return 'success'
      if ((enable) && (uptime === null)) return 'error'
      return ''
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
