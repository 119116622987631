<template>
  <v-container>
    <olt-detail-card-container :id="id" />
    <olt-interface-table-list-card-container :id="id" />
  </v-container>
</template>

<script>

// import axios from 'axios'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import OltDetailCardContainer from './OltDetailCardContainer.vue'
import OltInterfaceTableListCardContainer from './OltInterfaceTableListCardContainer.vue'

moment.locale('th')

export default {
  name: 'OltDetail',
  components: {
    OltDetailCardContainer,
    OltInterfaceTableListCardContainer,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      search: '',
      // totalOlts: 0,
      options: {},
      // olts: [],
      // loading: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Project',
          value: 'oltProject.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'IP Address',
          value: 'ip',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Brand',
          value: 'oltType.oltBrand.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Type',
          value: 'oltType.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Sector',
          value: 'officeSector.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Uptime',
          value: 'uptime.uptime',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Updated at',
          value: 'uptime.updatedAt',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      olts: 'olts',
      totalOlts: 'totalOlts',
      loading: 'isLoading',
    }),
    // ...mapState({
    //   olts: 'olts',
    //   totalOlts: 'totalOlts',
    // }),
  },
  watch: {
    options: {
      handler() {
        // this.getDataFromAPI()
        this.showOlts()
      },
      deep: true,
    },
    search: {
      handler() {
        // this.getDataFromAPI()
        this.showOlts()
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    ...mapActions({
      getOlts: 'getOlts',
    }),
    showOlts() {
      // this.loading = true
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
      }
      this.getOlts(queryParams)
    },
    setStatusColor(enable, uptime) {
      if ((enable) && (uptime !== null)) return 'success'
      if ((enable) && (uptime === null)) return 'error'
      return ''
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
