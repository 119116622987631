<template>
  <v-container>
    <v-card
      v-if="loaded"
    >
      <v-data-table
        :headers="headers"
        :items="oltInterfaceTableWithGroup"
        disable-pagination
        disable-sort
        hide-default-footer
        group-by="ifPositionGroup"
      >
        <template v-slot:group.header="{ group, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn
              :ref="group"
              small
              icon
              :data-open="isOpen"
              @click="toggle"
            >
              <v-icon v-if="isOpen">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
            <b>
              {{ group }}
            </b>
          </td>
        </template>
        <template v-slot:item.status="{ item }">
          <!-- eslint-disable max-len -->
          <v-icon
            :color="setPortStatusColor(item.ifAdminStatus.ifAdminStatus, item.ifOperStatus.ifOperStatus)"
          >
            mdi-circle
          </v-icon>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <span>
            <!-- {{ formatDate(item.updatedAt) }} -->
            <v-chip
              v-if="item.updatedAt"
              :color="getFromNowColor(item.updatedAt)"
              dark
              small
            >
              {{ fromNow(item.updatedAt) }}
            </v-chip>
          </span>
        </template>
        <template v-slot:item.action="{ item }">
          <div class="text-center">
            <div>
              <v-btn
                v-if="item.ifType.ifType == 250"
                small
                color="primary"
                class="ma-2"
                outlined
                :to="'/oltinterfacetable/' + item._id + '/onulist'"
              >
                <v-icon
                  left
                >
                  mdi-router-wireless
                </v-icon>
                ONU
              </v-btn>
              <v-btn
                small
                color="primary"
                class="ma-2"
                outlined
                :to="'/oltinterfacetable/' + item._id + '/oltinterfacetraffic'"
              >
                <v-icon
                  left
                >
                  mdi-chart-areaspline
                </v-icon>
                Traffic
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

moment.locale('th')

export default {
  name: 'OltInterfaceTableListCardContainer',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  data() {
    return {
      oltInterfaceTable: '',
      loaded: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        // {
        //   text: 'Rack',
        //   value: 'ifPosition.rack',
        //   sortable: false,
        //   align: 'center',
        // },
        // {
        //   text: 'Shelf',
        //   value: 'ifPosition.shelf',
        //   sortable: false,
        //   align: 'center',
        // },
        // {
        //   text: 'Slot',
        //   value: 'ifPosition.slot',
        //   sortable: false,
        //   align: 'center',
        // },
        {
          text: 'Port',
          value: 'ifPosition.port',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Name',
          value: 'ifName.ifName',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Updated at',
          value: 'updatedAt',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    oltInterfaceTableWithGroup() {
      const oltInterfaceTable = this.oltInterfaceTable.map(obj => Object.assign({}, obj, {
        ifPositionGroup: `Rack ${obj.ifPosition.rack} : : Shelf ${obj.ifPosition.rack} : : Slot ${obj.ifPosition.slot}`,
      }))
      return oltInterfaceTable
    },
  },
  watch: {
    options: {
      handler() {
        this.showOltInterfaceTable()
      },
      deep: true,
    },
    search: {
      handler() {
        this.showOltInterfaceTable()
      },
      deep: true,
    },
  },
  mounted() {
    this.loaded = false
    this.showOltInterfaceTable()
    this.interval = setInterval(() => {
      this.showOltInterfaceTable()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {

  },
  methods: {
    showOltInterfaceTable() {
      this.loaded = false
      axios.get(`/olt/${this.id}/oltInterfaceTable`)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.oltInterfaceTable = response.data
          this.loaded = true
        })
        .catch((e) => {
          this.errors.push(e)
          this.loaded = false
        })
    },
    setPortStatusColor(ifAdminStatus, ifOperStatus) {
      if (ifAdminStatus === 2) return ''
      if ((ifAdminStatus === 1) && (ifOperStatus === 1)) return 'success'
      if ((ifAdminStatus === 1) && (ifOperStatus === 2)) return 'error'
      return ''
    },
    formatDate(date) {
      return moment(date).format('LLL')
    },
    fromNow(date) {
      if (date) {
        return moment(date).fromNow()
      }
      return null
    },
    getFromNowColor(date) {
      if (date) {
        const now = moment(new Date())
        const duration = moment.duration(now.diff(date))
        const minuteDiff = duration.asMinutes()
        if (minuteDiff < 1) return 'success'
        if (minuteDiff < 2) return 'yellow'
        if (minuteDiff < 3) return 'amber'
        if (minuteDiff < 4) return 'orange'
        if (minuteDiff < 5) return 'deep-orange'
        return 'red'
      }
      return null
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
